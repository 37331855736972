var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "group-element",
        {
          attrs: {
            selectable: _vm.selectable,
            movable: _vm.movable,
            resizable: _vm.resizable,
            deletable: _vm.deletable,
            id: _vm.value.elementView.id,
            x: _vm.elementCoordinate.x,
            y: _vm.elementCoordinate.y,
            width: _vm.elementCoordinate.width,
            height: _vm.elementCoordinate.height,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
            label: _vm.namePanel,
            _style: {
              "vertical-align": "top",
              "font-weight": "bold",
              "font-size": "16",
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "height", $event)
            },
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            removeShape: _vm.onRemoveShape,
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            dblclick: _vm.openPanel,
            addToGroup: _vm.onAddToGroup,
            "update:label": function ($event) {
              _vm.namePanel = $event
            },
          },
        },
        [
          _c(
            "sub-elements",
            [
              _vm.movingElement
                ? _c("geometry-rect", {
                    attrs: {
                      _style: {
                        "fill-r": 1,
                        "fill-cx": 0.1,
                        "fill-cy": 0.1,
                        "stroke-width": 3,
                        stroke: "#000000",
                        fill: "#e3e1e1",
                        "fill-opacity": 0.5,
                        "vertical-align": "top",
                        "font-weight": "bold",
                        "font-size": "16",
                        r: "1",
                      },
                    },
                  })
                : _c("geometry-rect", {
                    attrs: {
                      _style: {
                        "fill-r": 1,
                        "fill-cx": 0.1,
                        "fill-cy": 0.1,
                        "stroke-width": 3,
                        stroke: "#000000",
                        "fill-opacity": 1,
                        "vertical-align": "top",
                        "font-weight": "bold",
                        "font-size": "16",
                        r: "1",
                      },
                    },
                  }),
            ],
            1
          ),
          _c(
            "sub-elements",
            _vm._l(_vm.innerAggregate.length, function (index) {
              return _c("rectangle-element", {
                attrs: {
                  "sub-width": "90%",
                  "sub-left": "5%",
                  "sub-height": _vm.calInnerView(
                    index,
                    _vm.innerAggregate.length
                  ).height,
                  "sub-top": _vm.calInnerView(index, _vm.innerAggregate.length)
                    .top,
                  subStyle: {
                    stroke: "#F8D454",
                    fill: "#F8D454",
                    "fill-opacity": 1,
                  },
                  label: _vm.innerAggregate[index - 1].name,
                },
                on: {
                  "update:label": function ($event) {
                    return _vm.$set(
                      _vm.innerAggregate[index - 1],
                      "name",
                      $event
                    )
                  },
                },
              })
            }),
            1
          ),
          _c(
            "sub-elements",
            _vm._l(_vm.newEditUserImg.length, function (index) {
              return _c("image-element", {
                key: index,
                attrs: {
                  image: _vm.newEditUserImg[index - 1].picture,
                  "sub-width": "24px",
                  "sub-height": "24px",
                  "sub-right": 10 * (index - 1) + "px",
                  "sub-bottom": _vm.value.elementView.height,
                },
              })
            }),
            1
          ),
          _vm.value.modelValue.projectId
            ? _c("sub-controller", {
                attrs: { image: "open-in-new.png" },
                on: { click: _vm.openProject },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.propertyPanel
        ? _c("pbc-cm-panel", {
            attrs: {
              isReadOnly: !_vm.isEditElement,
              newEditUserImg: _vm.newEditUserImg,
              image: _vm.image,
              validationLists: _vm.filteredElementValidationResults,
            },
            on: {
              close: _vm.closePanel,
              changedPanelValue: _vm.changedPanelValue,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }