var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "edge-element",
        {
          attrs: {
            selectable: _vm.selectable,
            movable: _vm.movable,
            deletable: _vm.deletable,
            connectable: "",
            id: _vm.value.relationView.id,
            vertices: _vm.vertices,
            from: _vm.value.from,
            to: _vm.value.to,
            _style: _vm.style_,
            label: _vm.value.name,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.relationView, "id", $event)
            },
            "update:vertices": function ($event) {
              _vm.vertices = $event
            },
            "update:from": function ($event) {
              return _vm.$set(_vm.value, "from", $event)
            },
            "update:to": function ($event) {
              return _vm.$set(_vm.value, "to", $event)
            },
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            dblclick: _vm.openPanel,
            customRelationMoveAction: _vm.delayedRelationMove,
            removeShape: _vm.onRemoveShape,
          },
        },
        [
          _c(
            "sub-elements",
            [
              _c("text-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-height": 25,
                  "sub-top": 5,
                  text: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.propertyPanel
        ? _c("class-relation-panel", {
            attrs: {
              isReadOnly: !_vm.isEditElement,
              newEditUserImg: _vm.newEditUserImg,
              image: _vm.image,
              validationLists: _vm.filteredElementValidationResults,
            },
            on: {
              close: _vm.closePanel,
              changedPanelValue: _vm.changedPanelValue,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }