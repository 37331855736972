import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    [
      _c(VCardTitle, [_vm._v("BoundedContext Reconstruction Draft")]),
      _c(VCardSubtitle, [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _vm.draftUIInfos.leftBoundedContextCount > 0
              ? _c("div", [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      _vm._s(_vm.draftUIInfos.leftBoundedContextCount) +
                        " Bounded Contexts remaining..."
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.draftUIInfos.leftBoundedContextCount > 0
              ? _c(VProgressCircular, {
                  staticClass: "ml-2",
                  attrs: { color: "primary", indeterminate: "", size: "24" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.DDLDraftOptions && _vm.DDLDraftOptions.length > 0
        ? _c(
            VCardText,
            [
              _c(
                VTabs,
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(
                  _vm.DDLDraftOptions,
                  function (boundedContextInfo, index) {
                    return _c(VTab, { key: index }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(boundedContextInfo.boundedContext)
                      ),
                      _c("br"),
                    ])
                  }
                ),
                1
              ),
              _c(
                VTabsItems,
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(
                  _vm.DDLDraftOptions,
                  function (boundedContextInfo, index) {
                    return _c(
                      VTabItem,
                      { key: index },
                      [
                        _c("div", { staticClass: "d-flex align-center mb-2" }, [
                          _c("h3", [
                            _vm._v(
                              "Bounded Context: " +
                                _vm._s(boundedContextInfo.boundedContext)
                            ),
                          ]),
                        ]),
                        _c(
                          VRadioGroup,
                          {
                            model: {
                              value:
                                _vm.selectedOptionItem[
                                  boundedContextInfo.boundedContext
                                ],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedOptionItem,
                                  boundedContextInfo.boundedContext,
                                  $$v
                                )
                              },
                              expression:
                                "selectedOptionItem[boundedContextInfo.boundedContext]",
                            },
                          },
                          _vm._l(
                            boundedContextInfo.options,
                            function (option, index) {
                              return _c(
                                VCard,
                                { key: index, staticClass: "mb-4" },
                                [
                                  _c(
                                    VCardTitle,
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Option " + _vm._s(index + 1)),
                                      ]),
                                      _c(VRadio, {
                                        attrs: { value: option },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(VCardText, [
                                    option.structure
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            option.structure,
                                            function (aggregate, index) {
                                              return _c("div", { key: index }, [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      aggregate.aggregateName
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    aggregate.entities.length >
                                                    0
                                                      ? _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Entities:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                aggregate.entities.join(
                                                                  ", "
                                                                )
                                                              )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    aggregate.valueObjects
                                                      .length > 0
                                                      ? _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "ValueObjects:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                aggregate.valueObjects.join(
                                                                  ", "
                                                                )
                                                              )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("br"),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                    _c("h4", [_vm._v("Pros:")]),
                                    _c("p", [_vm._v(_vm._s(option.pros))]),
                                    _c("h4", [_vm._v("Cons:")]),
                                    _c("p", [_vm._v(_vm._s(option.cons))]),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                        _c("h4", { staticClass: "mt-4" }, [
                          _vm._v("Conclusions:"),
                        ]),
                        _c("p", [
                          _vm._v(_vm._s(boundedContextInfo.conclusions)),
                        ]),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
              _c(
                VBtn,
                {
                  attrs: {
                    disabled:
                      !_vm.isGeneratorButtonEnabled ||
                      _vm.draftUIInfos.leftBoundedContextCount > 0 ||
                      !_vm.selectedOptionItem ||
                      Object.keys(_vm.selectedOptionItem).length !==
                        _vm.DDLDraftOptions.length,
                    block: "",
                  },
                  on: { click: _vm.generateFromDraft },
                },
                [_vm._v("Generate From Draft")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }