var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "group-element",
        {
          attrs: {
            selectable: _vm.selectable,
            movable: _vm.movable,
            resizable: _vm.resizable,
            deletable: _vm.deletable,
            id: _vm.value.elementView.id,
            x: _vm.elementCoordinate.x,
            y: _vm.elementCoordinate.y,
            width: _vm.elementCoordinate.width,
            height: _vm.elementCoordinate.height,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
            label: _vm.getNamePanel,
            _style: {
              "vertical-align": "top",
              "font-weight": "bold",
              "font-size": "16",
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "height", $event)
            },
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            removeShape: _vm.onRemoveShape,
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            dblclick: _vm.openPanel,
            addToGroup: _vm.onAddToGroup,
            "update:label": function ($event) {
              _vm.getNamePanel = $event
            },
          },
        },
        [
          _c(
            "sub-elements",
            [
              _vm.movingElement
                ? _c("geometry-rect", {
                    attrs: {
                      _style: {
                        "fill-r": 1,
                        "fill-cx": 0.1,
                        "fill-cy": 0.1,
                        "stroke-width": 1.4,
                        stroke: "#000000",
                        fill: "#e3e1e1",
                        "fill-opacity": 0.5,
                        "vertical-align": "top",
                        "font-weight": "bold",
                        "font-size": "16",
                        r: "1",
                      },
                    },
                  })
                : _c("geometry-rect", {
                    attrs: {
                      _style: {
                        "fill-r": 1,
                        "fill-cx": 0.1,
                        "fill-cy": 0.1,
                        "stroke-width": 1.4,
                        stroke: "#000000",
                        "fill-opacity": 1,
                        "vertical-align": "top",
                        "font-weight": "bold",
                        "font-size": "16",
                        r: "1",
                      },
                    },
                  }),
            ],
            1
          ),
          _c(
            "sub-elements",
            [
              _vm.value.mirrorElement
                ? _c("image-element", {
                    attrs: {
                      image: _vm.isProjectConnecting
                        ? _vm.link_image
                        : _vm.link_off_image,
                      "sub-width": "10px",
                      "sub-height": "10px",
                      "sub-left": "3px",
                      "sub-top": "3px",
                    },
                  })
                : _vm._e(),
              _vm._l(_vm.innerAggregate.length, function (index) {
                return _vm.innerAggregate.length > 0
                  ? _c("rectangle-element", {
                      attrs: {
                        "sub-width": "90%",
                        "sub-left": "5%",
                        "sub-height": _vm.calInnerView(
                          index,
                          _vm.innerAggregate.length
                        ).height,
                        "sub-top": _vm.calInnerView(
                          index,
                          _vm.innerAggregate.length
                        ).top,
                        subStyle: {
                          stroke: "#F8D454",
                          fill: "#F8D454",
                          "fill-opacity": 1,
                        },
                        label: _vm.innerAggregate[index - 1].name,
                      },
                      on: {
                        "update:label": function ($event) {
                          return _vm.$set(
                            _vm.innerAggregate[index - 1],
                            "name",
                            $event
                          )
                        },
                      },
                    })
                  : _vm._e()
              }),
            ],
            2
          ),
          _c("storming-sub-controller", {
            attrs: {
              type: _vm.value._type,
              value: _vm.value,
              isReadOnly: !_vm.isEditElement && !_vm.isMembers,
              canvasType: "cm",
              isProjectConnecting: _vm.isProjectConnecting,
            },
            on: {
              createDefinition: function ($event) {
                return _vm.createDefinition()
              },
            },
          }),
          _c(
            "sub-elements",
            _vm._l(_vm.newEditUserImg.length, function (index) {
              return _c("image-element", {
                key: index,
                attrs: {
                  image: _vm.newEditUserImg[index - 1].picture,
                  "sub-width": "24px",
                  "sub-height": "24px",
                  "sub-right": 10 * (index - 1) + "px",
                  "sub-bottom": _vm.elementCoordinate.height,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm.propertyPanel
        ? _c("bounded-context-cm-panel", {
            attrs: {
              isReadOnly: !_vm.isEditElement,
              newEditUserImg: _vm.newEditUserImg,
              image: _vm.image,
              validationLists: _vm.filteredElementValidationResults,
              generateDone: _vm.generateDone,
              generator: _vm.generator,
            },
            on: {
              close: _vm.closePanel,
              changedPanelValue: _vm.changedPanelValue,
              "update:generateDone": function ($event) {
                _vm.generateDone = $event
              },
              "update:generate-done": function ($event) {
                _vm.generateDone = $event
              },
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }