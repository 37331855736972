import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("separate-panel-components", {
        attrs: {
          min: _vm.mainSeparatePanel.min,
          max: _vm.mainSeparatePanel.max,
          triggerLength: 5,
          paneLengthPercent: _vm.mainSeparatePanel.current,
          inBoundSeparatePanel: false,
        },
        on: {
          "update:paneLengthPercent": function ($event) {
            return _vm.$set(_vm.mainSeparatePanel, "current", $event)
          },
          "update:pane-length-percent": function ($event) {
            return _vm.$set(_vm.mainSeparatePanel, "current", $event)
          },
          close: function ($event) {
            return _vm.closeSeparatePanel()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "one",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", height: "100%" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "canvas-panel",
                          staticStyle: { left: "0" },
                        },
                        [
                          _c(
                            VSnackbar,
                            {
                              attrs: {
                                outlined: "",
                                color: _vm.snackbar.color,
                                "multi-line":
                                  _vm.snackbar.mode === "multi-line",
                                timeout: _vm.snackbar.timeout,
                                vertical: _vm.snackbar.mode === "vertical",
                                top: _vm.snackbar.top,
                                bottom: _vm.snackbar.bottom,
                                centered: _vm.snackbar.centered,
                              },
                              model: {
                                value: _vm.snackbar.show,
                                callback: function ($$v) {
                                  _vm.$set(_vm.snackbar, "show", $$v)
                                },
                                expression: "snackbar.show",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.snackbar.text) +
                                  "\n            "
                              ),
                              _vm.snackbar.closeBtn
                                ? _c(
                                    VBtn,
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { dark: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.snackbar.show = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              close\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            VLayout,
                            { attrs: { right: "" } },
                            [
                              _c(
                                "div",
                                { key: _vm.canvasRenderKey },
                                [
                                  _vm.value
                                    ? _c(
                                        "opengraph",
                                        {
                                          ref: "opengraph",
                                          attrs: {
                                            width: 100000,
                                            height: 100000,
                                            sliderLocationScale:
                                              _vm.sliderLocationScale,
                                            "focus-canvas-on-select": "",
                                            wheelScalable: "",
                                            labelEditable: true,
                                            dragPageMovable:
                                              _vm.dragPageMovable,
                                            enableContextmenu: false,
                                            automaticGuidance:
                                              _vm.automaticGuidance,
                                            enableRootContextmenu: false,
                                            enableHotkeyCtrlC: false,
                                            enableHotkeyCtrlV: false,
                                            enableHotkeyDelete: false,
                                            enableHotkeyCtrlZ: false,
                                            enableHotkeyCtrlD: false,
                                            enableHotkeyCtrlG: false,
                                            slider: true,
                                            movable: !_vm.isReadOnlyModel,
                                            resizable: !_vm.isReadOnlyModel,
                                            selectable: true,
                                            connectable: !_vm.isReadOnlyModel,
                                            autoSliderUpdate: true,
                                            imageBase: _vm.imageBase,
                                          },
                                          on: {
                                            "update:sliderLocationScale":
                                              function ($event) {
                                                _vm.sliderLocationScale = $event
                                              },
                                            connectShape: _vm.onConnectShape,
                                            canvasReady: _vm.bindEvents,
                                          },
                                        },
                                        [
                                          _vm._l(
                                            Object.keys(_vm.value.elements),
                                            function (elementId) {
                                              return _vm.value.elements &&
                                                typeof _vm.value.elements ==
                                                  "object"
                                                ? _c(
                                                    "div",
                                                    [
                                                      elementId &&
                                                      _vm.value.elements[
                                                        elementId
                                                      ]
                                                        ? _c(
                                                            _vm.getComponentByClassName(
                                                              _vm.value
                                                                .elements[
                                                                elementId
                                                              ]._type
                                                            ),
                                                            {
                                                              ref: elementId,
                                                              refInFor: true,
                                                              tag: "component",
                                                              attrs: {
                                                                value:
                                                                  _vm.value
                                                                    .elements[
                                                                    elementId
                                                                  ],
                                                              },
                                                              on: {
                                                                "update:value":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.value
                                                                        .elements,
                                                                      elementId,
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          _vm._l(
                                            Object.keys(_vm.value.relations),
                                            function (relationId) {
                                              return _vm.value.relations &&
                                                typeof _vm.value.relations ==
                                                  "object"
                                                ? _c(
                                                    "div",
                                                    [
                                                      relationId &&
                                                      _vm.value.relations[
                                                        relationId
                                                      ]
                                                        ? _c(
                                                            _vm.getComponentByClassName(
                                                              _vm.value
                                                                .relations[
                                                                relationId
                                                              ]._type
                                                            ),
                                                            {
                                                              ref: relationId,
                                                              refInFor: true,
                                                              tag: "component",
                                                              attrs: {
                                                                value:
                                                                  _vm.value
                                                                    .relations[
                                                                    relationId
                                                                  ],
                                                              },
                                                              on: {
                                                                "update:value":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.value
                                                                        .relations,
                                                                      relationId,
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              !_vm.isReadOnlyModel && !_vm.isHexagonal
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticClass: "gs-modeling-undo-redo",
                                        },
                                        [
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "gs-model-z-index-2 gs-undo-opacity-hover",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isUndoDisabled,
                                                                text: "",
                                                                small: "",
                                                                right: "",
                                                              },
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.undo()
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                attrs: {
                                                                  medium: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-undo"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                695928026
                                              ),
                                            },
                                            [_c("span", [_vm._v("Undo")])]
                                          ),
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "gs-model-z-index-2 gs-redo-opacity-hover",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isRedoDisabled,
                                                                text: "",
                                                                small: "",
                                                                right: "",
                                                              },
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.redo()
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                attrs: {
                                                                  medium: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-redo"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                237927002
                                              ),
                                            },
                                            [_c("span", [_vm._v("Redo")])]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { margin: "0 auto" } },
                                [
                                  _vm._t("top", function () {
                                    return [
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VRow,
                                            {
                                              staticClass:
                                                "gs-model-z-index-1 es-is-mobile-project-name",
                                              staticStyle: {
                                                margin: "5px 5px 0px 0px",
                                                height: "64px",
                                                "max-width": "200px",
                                                "background-color":
                                                  "transparent",
                                              },
                                            },
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticStyle: {
                                                    height: "24px",
                                                    "margin-top": "38px",
                                                  },
                                                  attrs: {
                                                    disabled: _vm.disableBtn,
                                                    color: "#8d8d8d",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openExportDialog()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-cog\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._t(
                                                "projectName",
                                                function () {
                                                  return [
                                                    _c(VTextField, {
                                                      staticClass:
                                                        "es-modeling-project-name",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isReadOnlyModel ||
                                                          (_vm.fullPath &&
                                                            _vm.fullPath.includes(
                                                              "replay"
                                                            )),
                                                        color:
                                                          _vm.projectNameColor,
                                                        "error-messages":
                                                          _vm.projectNameHint,
                                                        label: "Project Name",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.unselectedAll.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.projectName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.projectName = $$v
                                                        },
                                                        expression:
                                                          "projectName",
                                                      },
                                                    }),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "es-is-not-mobile",
                                              staticStyle: {
                                                margin: "40px 0px 0px 15px",
                                                "z-index": "1",
                                              },
                                            },
                                            [
                                              _c(
                                                VRow,
                                                {
                                                  staticStyle: {
                                                    "margin-right": "15px",
                                                  },
                                                  attrs: {
                                                    justify: "end",
                                                    align: "start",
                                                  },
                                                },
                                                [
                                                  _vm._t(
                                                    "menu-buttons",
                                                    function () {
                                                      return [
                                                        _c(VMenu, {
                                                          staticClass: "pa-2",
                                                          attrs: {
                                                            "open-on-hover": "",
                                                            "offset-y": "",
                                                            left: "",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        VBtn,
                                                                        {
                                                                          staticClass:
                                                                            "gs-model-z-index-1 es-hide-k8s-btn",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "5px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                              text: "",
                                                                              disabled:
                                                                                _vm.disableBtn,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.openEmbeddedCanvas(
                                                                                  "Kubernetes"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VIcon,
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-kubernetes"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "es-hide-k8s",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "DEPLOY"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm.isServerModel &&
                                                        !_vm.isClazzModeling
                                                          ? _c(
                                                              VMenu,
                                                              {
                                                                staticClass:
                                                                  "pa-2",
                                                                attrs: {
                                                                  "open-on-hover":
                                                                    "",
                                                                  "offset-y":
                                                                    "",
                                                                  left: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm.isHexagonal
                                                                                  ? _c(
                                                                                      VBtn,
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "gs-model-z-index-1",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-right":
                                                                                                "5px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              text: "",
                                                                                              color:
                                                                                                "primary",
                                                                                              disabled:
                                                                                                _vm.disableBtn,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.generateModel()
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          VIcon,
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-hexagon-outline"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Hexagonal"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _c(
                                                                                      VBtn,
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "gs-model-z-index-1 es-hide-view-btn",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-right":
                                                                                                "5px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              text: "",
                                                                                              color:
                                                                                                "primary",
                                                                                              disabled:
                                                                                                _vm.disableBtn,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.generateHexagonal()
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          VIcon,
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-view-dashboard"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "es-hide-view",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "VIEW"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2799447429
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  VList,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        overflow:
                                                                          "hidden",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.conversionItems,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        VListItem,
                                                                        {
                                                                          key: index,
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.functionSelect(
                                                                                  item.title,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemTitle,
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.title
                                                                                ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.isServerModel &&
                                                        !_vm.isClazzModeling
                                                          ? _c(
                                                              VMenu,
                                                              {
                                                                staticClass:
                                                                  "pa-2",
                                                                attrs: {
                                                                  "open-on-click":
                                                                    "",
                                                                  "offset-y":
                                                                    "",
                                                                  left: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  VBtn,
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "gs-model-z-index-1 es-hide-replay-btn",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-right":
                                                                                            "5px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          text: "",
                                                                                          color:
                                                                                            "primary",
                                                                                          disabled:
                                                                                            _vm.disableBtn,
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.loadVersions()
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      VIcon,
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-restart"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "es-hide-replay",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Versions"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    4221748513
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  VList,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        overflow:
                                                                          "hidden",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm.versionLists
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "text-align-last":
                                                                                      "center",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                VERSIONS\n                              "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              VDivider
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "overflow-y":
                                                                                      "scroll",
                                                                                    height:
                                                                                      "200px",
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                _vm.filteredVersionLists,
                                                                                function (
                                                                                  item,
                                                                                  index
                                                                                ) {
                                                                                  return _c(
                                                                                    VListItem,
                                                                                    {
                                                                                      key: index,
                                                                                      attrs:
                                                                                        {
                                                                                          "two-line":
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.moveToVersion(
                                                                                              item
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        VListItemContent,
                                                                                        [
                                                                                          _c(
                                                                                            VListItemTitle,
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "medium",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  item.version
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            VListItemSubtitle,
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "10px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.convertTimeStampToDate(
                                                                                                    item.timeStamp
                                                                                                  )
                                                                                                ) +
                                                                                                  "\n                                    "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              VDivider
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      VListItem,
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "-5px",
                                                                            "margin-bottom":
                                                                              "-10px",
                                                                            "text-align":
                                                                              "start",
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.saveComposition(
                                                                                "save"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          VListItemTitle,
                                                                          [
                                                                            _c(
                                                                              VIcon,
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .icon
                                                                                      .save
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              "CREATE VERSION\n                              "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      VListItem,
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "-5px",
                                                                            "margin-bottom":
                                                                              "-10px",
                                                                            "text-align":
                                                                              "start",
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showReplay()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          VListItemTitle,
                                                                          [
                                                                            _c(
                                                                              VIcon,
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-restart"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                REPLAY"
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          VMenu,
                                                          {
                                                            staticClass: "pa-2",
                                                            attrs: {
                                                              "open-on-hover":
                                                                "",
                                                              "offset-y": "",
                                                              left: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _vm.isReadOnlyModel
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                VBtn,
                                                                                {
                                                                                  staticClass:
                                                                                    "gs-model-z-index-1 es-hide-fork-btn",
                                                                                  staticStyle:
                                                                                    {
                                                                                      "margin-right":
                                                                                        "5px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      text: "",
                                                                                      color:
                                                                                        "primary",
                                                                                      disabled:
                                                                                        _vm.disableBtn,
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.saveComposition(
                                                                                          "fork"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    VIcon,
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .icon
                                                                                            .fork
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "es-hide-fork",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "FORK"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              !_vm.projectVersion
                                                                                ? _c(
                                                                                    VBtn,
                                                                                    {
                                                                                      staticClass:
                                                                                        "gs-model-z-index-1 es-hide-join-btn",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-right":
                                                                                            "5px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            _vm
                                                                                              .joinRequestedText
                                                                                              .show
                                                                                              ? "primary"
                                                                                              : "success",
                                                                                          text: "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.requestInviteUser()
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm
                                                                                        .joinRequestedText
                                                                                        .show
                                                                                        ? _c(
                                                                                            "div",
                                                                                            [
                                                                                              _c(
                                                                                                VIcon,
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .icon
                                                                                                        .join
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "es-hide-join",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                  " +
                                                                                              _vm._s(
                                                                                                _vm
                                                                                                  .joinRequestedText
                                                                                                  .text
                                                                                              ) +
                                                                                              "\n                                "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              _vm.showSaveBtn
                                                                                ? _c(
                                                                                    VBtn,
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "gs-model-z-index-1 es-hide-save-btn",
                                                                                        staticStyle:
                                                                                          {
                                                                                            "margin-right":
                                                                                              "5px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            text: "",
                                                                                            color:
                                                                                              "primary",
                                                                                            disabled:
                                                                                              !_vm.isServerModel,
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.saveComposition(
                                                                                                "save"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _c(
                                                                                        VIcon,
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .icon
                                                                                                .save
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "es-hide-save",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "SAVE"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _c(
                                                                                    VBtn,
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "gs-model-z-index-1",
                                                                                        staticStyle:
                                                                                          {
                                                                                            "margin-right":
                                                                                              "5px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            text: "",
                                                                                            color:
                                                                                              "primary",
                                                                                            disabled:
                                                                                              _vm.disableBtn,
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.saveComposition(
                                                                                                "fork"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _c(
                                                                                        VIcon,
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .icon
                                                                                                .fork
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "es-hide-fork",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "FORK"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            !_vm.isClazzModeling
                                                              ? _c(
                                                                  VList,
                                                                  _vm._l(
                                                                    _vm.filteredSaveItems,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        VListItem,
                                                                        {
                                                                          key: index,
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.functionSelect(
                                                                                  item.title,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemTitle,
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.title
                                                                                ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.isOwnModel &&
                                                        _vm.isServerModel &&
                                                        !_vm.isReadOnlyModel
                                                          ? _c(
                                                              VMenu,
                                                              {
                                                                attrs: {
                                                                  "offset-y":
                                                                    "",
                                                                  "open-on-hover":
                                                                    "",
                                                                  left: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  VBtn,
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "gs-model-z-index-1 es-hide-share-btn",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-right":
                                                                                            "5px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          text: "",
                                                                                          color:
                                                                                            "primary",
                                                                                          disabled:
                                                                                            !_vm.initLoad,
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openInviteUsers()
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      VIcon,
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .icon
                                                                                              .share
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "es-hide-share",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "SHARE"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm.requestCount
                                                                                      ? _c(
                                                                                          VAvatar,
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "margin-left":
                                                                                                  "2px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                size: "25",
                                                                                                color:
                                                                                                  "red",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  _vm.requestCount
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    104075761
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  VList,
                                                                  _vm._l(
                                                                    _vm.shareItems,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        VListItem,
                                                                        {
                                                                          key: index,
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.functionSelect(
                                                                                  item.title,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemTitle,
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.title
                                                                                ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          VMenu,
                                                          {
                                                            attrs: {
                                                              "offset-y": "",
                                                              "open-on-hover":
                                                                "",
                                                              left: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            VBtn,
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "gs-model-z-index-1 es-hide-code-btn",
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-right":
                                                                                      "5px",
                                                                                    color:
                                                                                      "white",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "primary",
                                                                                    disabled:
                                                                                      !_vm.initLoad,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openCodeViewer()
                                                                                    },
                                                                                },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                VIcon,
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .icon
                                                                                        .code
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "CODE"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm.ideWindow
                                                                                ? _c(
                                                                                    VProgressCircular,
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          indeterminate:
                                                                                            "",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              VList,
                                                              {
                                                                staticStyle: {
                                                                  width:
                                                                    "175px",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.codeItems,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    VListItem,
                                                                    {
                                                                      key: index,
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.functionSelect(
                                                                              item.title,
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        VListItemTitle,
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.title
                                                                            ) +
                                                                              "\n                              "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "es-is-mobile" },
                                        [
                                          _c(
                                            VRow,
                                            {
                                              staticClass: "mobile-action-btn",
                                              staticStyle: {
                                                display: "flex",
                                                "margin-right": "50px",
                                              },
                                              attrs: {
                                                justify: "center",
                                                align: "start",
                                              },
                                            },
                                            [
                                              _c(
                                                VMenu,
                                                {
                                                  attrs: {
                                                    "open-on-hover": "",
                                                    "offset-y": "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _vm.isReadOnlyModel &&
                                                          !_vm.projectVersion
                                                            ? _c(
                                                                VBtn,
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    color: _vm
                                                                      .joinRequestedText
                                                                      .show
                                                                      ? "primary"
                                                                      : "success",
                                                                    dark: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.requestInviteUser()
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .joinRequestedText
                                                                    .show
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            VIcon,
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .icon
                                                                                    .join
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .joinRequestedText
                                                                          .text
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.isReadOnlyModel
                                                            ? _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mobile-btn",
                                                                    attrs: {
                                                                      text: "",
                                                                      color:
                                                                        "primary",
                                                                      dark: "",
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.saveComposition(
                                                                            "fork"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .fork
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mobile-btn",
                                                                    attrs: {
                                                                      text: "",
                                                                      color:
                                                                        "primary",
                                                                      dark: "",
                                                                      small: "",
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.saveComposition(
                                                                            "save"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .save
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  !_vm.isClazzModeling
                                                    ? _c(
                                                        VList,
                                                        _vm._l(
                                                          _vm.filteredSaveItems,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              VListItem,
                                                              {
                                                                key: index,
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.functionSelect(
                                                                        item.title,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VListItemTitle,
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.title
                                                                      ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm.isOwnModel &&
                                              _vm.isServerModel &&
                                              !_vm.isReadOnlyModel
                                                ? _c(
                                                    VMenu,
                                                    {
                                                      staticClass: "pa-2",
                                                      attrs: {
                                                        "offset-y": "",
                                                        "open-on-hover": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      VBtn,
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "mobile-btn",
                                                                          attrs:
                                                                            {
                                                                              text: "",
                                                                              color:
                                                                                "primary",
                                                                              dark: "",
                                                                              small:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.openInviteUsers()
                                                                              },
                                                                          },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          VIcon,
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .icon
                                                                                  .share
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm.requestCount
                                                                          ? _c(
                                                                              VAvatar,
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-left":
                                                                                      "2px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    size: "25",
                                                                                    color:
                                                                                      "red",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.requestCount
                                                                                    ) +
                                                                                    "\n                            "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1189929935
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        VList,
                                                        _vm._l(
                                                          _vm.shareItems,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              VListItem,
                                                              {
                                                                key: index,
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.functionSelect(
                                                                        item.title,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VListItemTitle,
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.title
                                                                      ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isServerModel &&
                                              !_vm.isClazzModeling
                                                ? _c(VMenu, {
                                                    staticClass: "pa-2",
                                                    attrs: {
                                                      text: "",
                                                      "open-on-hover": "",
                                                      "offset-y": "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    VBtn,
                                                                    {
                                                                      staticClass:
                                                                        "mobile-btn",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        dark: "",
                                                                        small:
                                                                          "",
                                                                        disabled:
                                                                          _vm.disableBtn,
                                                                        text: "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.showReplay()
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-restart"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2741223403
                                                    ),
                                                  })
                                                : _vm._e(),
                                              _c(
                                                VMenu,
                                                {
                                                  attrs: {
                                                    "open-on-hover": "",
                                                    "offset-y": "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mobile-btn",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      dark: "",
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openCodeViewer()
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .icon
                                                                            .code
                                                                        )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  _c(
                                                    VList,
                                                    _vm._l(
                                                      _vm.codeItems,
                                                      function (item, index) {
                                                        return _c(
                                                          VListItem,
                                                          {
                                                            key: index,
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.functionSelect(
                                                                  item.title,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                    "\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(VMenu, {
                                                staticClass: "pa-2",
                                                attrs: {
                                                  "open-on-hover": "",
                                                  "offset-y": "",
                                                  left: "",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              VBtn,
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "-8px",
                                                                  "margin-top":
                                                                    "1px",
                                                                },
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  text: "",
                                                                  disabled:
                                                                    _vm.disableBtn,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.openEmbeddedCanvas(
                                                                        "Kubernetes"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    "mdi-kubernetes"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              }),
                                              _c(
                                                VMenu,
                                                {
                                                  staticClass: "pa-2",
                                                  attrs: {
                                                    "open-on-hover": "",
                                                    "offset-y": "",
                                                    left: "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            [
                                                              _vm.isHexagonal
                                                                ? _c(
                                                                    VBtn,
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "gs-model-z-index-1",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "-20px",
                                                                            "margin-top":
                                                                              "1px",
                                                                          },
                                                                        attrs: {
                                                                          text: "",
                                                                          color:
                                                                            "primary",
                                                                          disabled:
                                                                            _vm.disableBtn,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.generateModel()
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-hexagon-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    VBtn,
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "gs-model-z-index-1",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "-20px",
                                                                            "margin-top":
                                                                              "1px",
                                                                          },
                                                                        attrs: {
                                                                          text: "",
                                                                          color:
                                                                            "primary",
                                                                          disabled:
                                                                            _vm.disableBtn,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.generateHexagonal()
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-view-dashboard"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  _c(
                                                    VList,
                                                    _vm._l(
                                                      _vm.conversionItems,
                                                      function (item, index) {
                                                        return _c(
                                                          VListItem,
                                                          {
                                                            key: index,
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.functionSelect(
                                                                  item.title,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                    "\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(VMenu, {
                                                staticClass: "pa-2",
                                                attrs: {
                                                  "open-on-hover": "",
                                                  "offset-y": "",
                                                  left: "",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          [
                                                            _vm.isHexagonal
                                                              ? _c(
                                                                  VBtn,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "-20px",
                                                                        "margin-top":
                                                                          "1px",
                                                                      },
                                                                    attrs: {
                                                                      text: "",
                                                                      color:
                                                                        "primary",
                                                                      disabled:
                                                                        _vm.disableBtn,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.generateModel()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VIcon,
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              mdi-checkbox-multiple-blank-outline\n                            "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  VBtn,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "-20px",
                                                                        "margin-top":
                                                                          "1px",
                                                                      },
                                                                    attrs: {
                                                                      text: "",
                                                                      color:
                                                                        "primary",
                                                                      disabled:
                                                                        _vm.disableBtn,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.generateHexagonal()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VIcon,
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-hexagon-outline"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                              _c(
                                VCard,
                                {
                                  staticClass: "tools",
                                  staticStyle: {
                                    top: "100px",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "bpmn-icon-hand-tool",
                                                      class: {
                                                        icons:
                                                          !_vm.dragPageMovable,
                                                        hands:
                                                          _vm.dragPageMovable,
                                                      },
                                                      attrs: {
                                                        _width: "30",
                                                        _height: "30",
                                                      },
                                                      on: {
                                                        click: _vm.toggleGrip,
                                                      },
                                                    },
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _vm.dragPageMovable == true
                                        ? _c("span", [
                                            _vm._v("Draggable Screen : on"),
                                          ])
                                        : _vm._e(),
                                      _vm.dragPageMovable == false
                                        ? _c("span", [
                                            _vm._v("Draggable Screen : off"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  !_vm.isReadOnlyModel
                                    ? _c(
                                        VTooltip,
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "gs-automatic-guidance-btn",
                                                            on: {
                                                              click:
                                                                _vm.automaticGuidanceChange,
                                                            },
                                                          },
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm.automaticGuidance ==
                                                        true
                                                          ? _c(
                                                              VIcon,
                                                              {
                                                                staticClass:
                                                                  "gs-automatic-guidance-icon",
                                                                staticStyle: {
                                                                  color:
                                                                    "#fbc02d",
                                                                },
                                                                attrs: {
                                                                  large: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-border-inside"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.automaticGuidance ==
                                                        false
                                                          ? _c(
                                                              VIcon,
                                                              {
                                                                staticClass:
                                                                  "gs-automatic-guidance-icon",
                                                                attrs: {
                                                                  large: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-border-none"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3766027503
                                          ),
                                        },
                                        [
                                          _vm.automaticGuidance == true
                                            ? _c("span", [
                                                _vm._v("Arrange Guidance : on"),
                                              ])
                                            : _vm._e(),
                                          _vm.automaticGuidance == false
                                            ? _c("span", [
                                                _vm._v(
                                                  "Arrange Guidance : off"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.elementTypes,
                                    function (item, key) {
                                      return _c(
                                        VTooltip,
                                        {
                                          key: key,
                                          attrs: {
                                            large: "",
                                            "nudge-top": "10",
                                            right: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "draggable",
                                                        attrs: {
                                                          align: "center",
                                                          _component:
                                                            item.component,
                                                          _width: item.width,
                                                          _height: item.height,
                                                        },
                                                      },
                                                      [
                                                        !_vm.isReadOnlyModel
                                                          ? _c(
                                                              "img",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    height:
                                                                      "30px",
                                                                    width:
                                                                      "30px",
                                                                    src: item.src,
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.label)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c(
                                VCard,
                                { staticClass: "mobile-first-sticker-tools" },
                                [
                                  _vm._l(
                                    _vm.elementTypes.slice(0, 5),
                                    function (item, key) {
                                      return _c(
                                        VTooltip,
                                        {
                                          key: key,
                                          attrs: {
                                            large: "",
                                            "nudge-top": "10",
                                            right: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "draggable",
                                                        staticStyle: {
                                                          margin:
                                                            "6px 6px 0px 6px",
                                                        },
                                                        attrs: {
                                                          align: "center",
                                                          _component:
                                                            item.component,
                                                          _width: item.width,
                                                          _height: item.height,
                                                        },
                                                      },
                                                      [
                                                        !_vm.isReadOnlyModel &&
                                                        (!_vm.isHexagonal ||
                                                          (_vm.isHexagonal &&
                                                            (item.component.includes(
                                                              "bounded-context"
                                                            ) ||
                                                              item.component.includes(
                                                                "packaged-business-capabilities"
                                                              ))))
                                                          ? _c(
                                                              "img",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    height:
                                                                      "30px",
                                                                    width:
                                                                      "30px",
                                                                    src: item.src,
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.label)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "bpmn-icon-hand-tool",
                                                      class: {
                                                        icons:
                                                          !_vm.dragPageMovable,
                                                        hands:
                                                          _vm.dragPageMovable,
                                                      },
                                                      attrs: {
                                                        _width: "30",
                                                        _height: "30",
                                                      },
                                                      on: {
                                                        click: _vm.toggleGrip,
                                                      },
                                                    },
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _vm.dragPageMovable == true
                                        ? _c("span", [
                                            _vm._v("Draggable Screen : on"),
                                          ])
                                        : _vm._e(),
                                      _vm.dragPageMovable == false
                                        ? _c("span", [
                                            _vm._v("Draggable Screen : off"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  !_vm.isReadOnlyModel
                                    ? _c(
                                        VTooltip,
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "gs-automatic-guidance-btn",
                                                            on: {
                                                              click:
                                                                _vm.automaticGuidanceChange,
                                                            },
                                                          },
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm.automaticGuidance ==
                                                        true
                                                          ? _c(
                                                              VIcon,
                                                              {
                                                                staticClass:
                                                                  "gs-automatic-guidance-icon",
                                                                staticStyle: {
                                                                  color:
                                                                    "#fbc02d",
                                                                  "margin-top":
                                                                    "3px",
                                                                },
                                                                attrs: {
                                                                  large: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-border-inside"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.automaticGuidance ==
                                                        false
                                                          ? _c(
                                                              VIcon,
                                                              {
                                                                staticClass:
                                                                  "gs-automatic-guidance-icon",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "3px",
                                                                },
                                                                attrs: {
                                                                  large: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-border-none"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3260374972
                                          ),
                                        },
                                        [
                                          _vm.automaticGuidance == true
                                            ? _c("span", [
                                                _vm._v("Arrange Guidance : on"),
                                              ])
                                            : _vm._e(),
                                          _vm.automaticGuidance == false
                                            ? _c("span", [
                                                _vm._v(
                                                  "Arrange Guidance : off"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _c(
                                VCard,
                                { staticClass: "mobile-second-sticker-tools" },
                                _vm._l(
                                  _vm.elementTypes.slice(5, 12),
                                  function (item, key) {
                                    return _c(
                                      VTooltip,
                                      {
                                        key: key,
                                        attrs: {
                                          large: "",
                                          "nudge-top": "10",
                                          right: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "draggable",
                                                      staticStyle: {
                                                        margin:
                                                          "6px 6.5px 0px 5px",
                                                      },
                                                      attrs: {
                                                        align: "center",
                                                        _component:
                                                          item.component,
                                                        _width: item.width,
                                                        _height: item.height,
                                                      },
                                                    },
                                                    [
                                                      !_vm.isReadOnlyModel &&
                                                      (!_vm.isHexagonal ||
                                                        (_vm.isHexagonal &&
                                                          (item.component.includes(
                                                            "bounded-context"
                                                          ) ||
                                                            item.component.includes(
                                                              "packaged-business-capabilities"
                                                            ))))
                                                        ? _c(
                                                            "img",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  height:
                                                                    "30px",
                                                                  width: "30px",
                                                                  src: item.src,
                                                                },
                                                              },
                                                              on
                                                            )
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v(_vm._s(item.label))])]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.showParticipantPanel
                        ? _c("ParticipantPanel", {
                            attrs: { lists: _vm.participantLists },
                          })
                        : _vm._e(),
                      _c("model-canvas-share-dialog", {
                        attrs: {
                          showDialog: _vm.inviteDialog,
                          checkPublic: _vm.showPublicModel,
                          canvas: _vm.canvas,
                          canvasComponentName: "event-storming-model-canvas",
                        },
                        on: {
                          all: _vm.invitePublic,
                          apply: _vm.applyInviteUsers,
                          close: _vm.closeInviteUsers,
                          add: _vm.addInviteUser,
                          remove: _vm.removeInviteUser,
                        },
                        model: {
                          value: _vm.inviteLists,
                          callback: function ($$v) {
                            _vm.inviteLists = $$v
                          },
                          expression: "inviteLists",
                        },
                      }),
                      _c("model-storage-dialog", {
                        attrs: {
                          condition: _vm.storageCondition,
                          showDialog: _vm.showStorageDialog,
                          isClazzModeling: _vm.isClazzModeling,
                        },
                        on: {
                          updateClassModelingId: _vm.updateClassModelingId,
                          save: _vm.saveModel,
                          fork: _vm.forkModel,
                          backup: _vm.backupModel,
                          close: _vm.storageDialogCancel,
                        },
                      }),
                      _c("dialog-purchase-item", {
                        attrs: {
                          "purchase-item-info": _vm.purchaseItemDialogInfo,
                        },
                        on: {
                          result: _vm.purchaseItemDialogSubmit,
                          close: _vm.purchaseItemDialogClose,
                        },
                        model: {
                          value: _vm.purchaseItemDialog,
                          callback: function ($$v) {
                            _vm.purchaseItemDialog = $$v
                          },
                          expression: "purchaseItemDialog",
                        },
                      }),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "290" },
                          model: {
                            value: _vm.forkAlertDialog,
                            callback: function ($$v) {
                              _vm.forkAlertDialog = $$v
                            },
                            expression: "forkAlertDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VCardTitle,
                                { staticClass: "headline" },
                                [
                                  _vm._v("Fork\n              "),
                                  _c(VIcon, [_vm._v(_vm._s(_vm.icon.fork))]),
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  "\n              권한이 없어서 수정 할 수 없습니다. Fork를 하여 사용해\n              주세요."
                                ),
                              ]),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "green darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveComposition("fork")
                                        },
                                      },
                                    },
                                    [_vm._v("Fork\n              ")]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "red darken-1",
                                        text: "",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          _vm.forkAlertDialog = false
                                        },
                                      },
                                    },
                                    [_vm._v("Close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          staticStyle: { height: "100%" },
                          attrs: { persistent: "", scrollable: "" },
                          model: {
                            value: _vm.modelingListsDialog,
                            callback: function ($$v) {
                              _vm.modelingListsDialog = $$v
                            },
                            expression: "modelingListsDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            { staticStyle: { height: "100%" } },
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "-10px",
                                  },
                                },
                                [_vm._v("Select Model for PBC")]
                              ),
                              _c(
                                VCardActions,
                                {
                                  staticStyle: {
                                    "justify-content": "flex-end",
                                  },
                                },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { small: "", text: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeModelingListsDialog()
                                        },
                                      },
                                    },
                                    [
                                      _c(VIcon, { attrs: { small: "" } }, [
                                        _vm._v("mdi-close"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                [
                                  _c("PBCModelList", {
                                    on: {
                                      "selected-model":
                                        _vm.applyModelingListsDialog,
                                      close: _vm.closeModelingListsDialog,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: {
                            "max-width": "1200",
                            "max-height": "800",
                            overflow: "scroll",
                          },
                          model: {
                            value: _vm.showDDLDraftDialog,
                            callback: function ($$v) {
                              _vm.showDDLDraftDialog = $$v
                            },
                            expression: "showDDLDraftDialog",
                          },
                        },
                        [
                          _c("ModelDraftDialogForDistribution", {
                            attrs: {
                              DDLDraftOptions: _vm.DDLDraftOptions,
                              draftUIInfos: _vm.draftUIInfos,
                              isGeneratorButtonEnabled:
                                _vm.isDraftGeneratorButtonEnabled,
                            },
                            on: {
                              reGenerate: _vm.reGenerate,
                              generateFromDraft: _vm.generateFromDraft,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("modeler-image-generator", {
                        ref: "modeler-image-generator",
                      }),
                    ],
                    1
                  ),
                  _vm.projectId
                    ? _c("GeneratorUI", {
                        ref: "generatorUI",
                        attrs: {
                          projectId: _vm.projectId,
                          modelValue: _vm.value,
                          embedded: _vm.embedded,
                          defaultInputData: _vm.defaultGeneratorUiInputData,
                        },
                        on: {
                          createModel: _vm.createModel,
                          clearModelValue: _vm.clearModelValue,
                          onGenerationFinished: _vm.onGenerationFinished,
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "two",
              fn: function () {
                return [
                  _c("CodeGenerator", {
                    attrs: {
                      isOwnModel: _vm.isOwnModel,
                      isServerModel: _vm.isServerModel,
                      projectInformation: _vm.information,
                      projectName: _vm.projectName,
                      modelInitLoad: _vm.initLoad,
                      modelingProjectId: _vm.projectId,
                      asyncCodeForValue: false,
                      callCodeForValue: _vm.changedTemplateCode,
                      oldTreeHashLists: _vm.oldTreeHashLists,
                      newTreeHashLists: _vm.newTreeHashLists,
                      projectVersion: _vm.projectVersion,
                      "canvas-name": "context-mapping-model-canvas",
                    },
                    on: {
                      "update:oldTreeHashLists": function ($event) {
                        _vm.oldTreeHashLists = $event
                      },
                      "update:old-tree-hash-lists": function ($event) {
                        _vm.oldTreeHashLists = $event
                      },
                      "update:newTreeHashLists": function ($event) {
                        _vm.newTreeHashLists = $event
                      },
                      "update:new-tree-hash-lists": function ($event) {
                        _vm.newTreeHashLists = $event
                      },
                      changedByMe: _vm.settingChangedByMe,
                      editModelData: _vm.editModelData,
                    },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }