import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
        "related-url": _vm.relatedUrl,
        "validation-lists": _vm.validationLists,
        "translate-obj": _vm.translateObj,
        "element-author-display": _vm.elementAuthorDisplay,
      },
      on: { close: _vm.closePanel, changeTranslate: _vm.changeTranslate },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "t-description-text" }),
      _c("template", { slot: "t-generation-text" }),
      _c("template", { slot: "t-edit-user" }, [
        _vm.newEditUserImg.length > 0 && _vm.isReadOnly
          ? _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  VChip,
                  {
                    staticStyle: { "font-weight": "bold" },
                    attrs: {
                      small: "",
                      color: "orange",
                      "text-color": "white",
                    },
                    on: {
                      "~click": function ($event) {
                        return _vm.forceEditPanel()
                      },
                    },
                  },
                  [
                    _c(
                      VAvatar,
                      { attrs: { left: "" } },
                      [_c(VIcon, [_vm._v("mdi-lead-pencil")])],
                      1
                    ),
                    _c(VRow, [
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.newEditUserImg[0].name) +
                            " is now editing..."
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(" ( Click to force editing ) ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("template", { slot: "element" }, [
        _c(
          "div",
          [
            _c(
              VCard,
              { attrs: { flat: "" } },
              [
                _c(VCardTitle, { staticStyle: { color: "#757575" } }, [
                  _vm._v(
                    "\n                    Selected Model Info\n                "
                  ),
                ]),
                _c(VCardText, [
                  _c("div", [
                    _vm._v(
                      "\n                        Project ID   :\n                        "
                    ),
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.moveToModel()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.selectedProjectId))]
                    ),
                  ]),
                  _c("div", [
                    _vm._v(
                      "\n                        Project Name : " +
                        _vm._s(_vm.value.modelValue.projectName) +
                        "\n                    "
                    ),
                  ]),
                  _c(
                    "div",
                    [
                      _vm._v(
                        "\n                        Selected Version\n                        "
                      ),
                      _c(VAutocomplete, {
                        attrs: {
                          items: _vm.versionNameLists,
                          "auto-select-first": true,
                          disabled: _vm.isReadOnly,
                          loading: _vm.loading,
                        },
                        model: {
                          value: _vm.selectVersion,
                          callback: function ($$v) {
                            _vm.selectVersion = $$v
                          },
                          expression: "selectVersion",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }