import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
        "related-url": _vm.relatedUrl,
        "validation-lists": _vm.validationLists,
        "translate-obj": _vm.translateObj,
        "element-author-display": _vm.elementAuthorDisplay,
      },
      on: {
        close: _vm.closePanel,
        changeTranslate: _vm.changeTranslate,
        "update:members": function ($event) {
          _vm.value.members = $event
        },
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "t-description-text" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("panelInfo.BoundedContextCMPanel")) +
            "\n        "
        ),
      ]),
      _c("template", { slot: "t-generation-text" }, [
        _vm._v("\n        Bounded Contexts become a name of the files\n    "),
      ]),
      _c(
        "template",
        { slot: "md-title-side" },
        [
          _vm.isReadOnly
            ? _c(
                VBtn,
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "margin-bottom": "5px",
                  },
                  attrs: { color: "primary", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.editCMPanel()
                    },
                  },
                },
                [_vm._v("Edit")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "t-edit-user" }),
      _c("template", { slot: "generateWithAi" }, [
        _c("div", [
          _c("span", [
            _vm.isReadOnly
              ? _c(
                  "div",
                  [
                    _vm.value.description && _vm.generateDone
                      ? _c(
                          VBtn,
                          {
                            staticClass: "auto-modeling-btn",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.generate()
                              },
                            },
                          },
                          [
                            _c(VIcon, [_vm._v("mdi-auto-fix")]),
                            _vm._v("(RE)Generate Inside"),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.value.description && !_vm.generateDone
                      ? _c(
                          VBtn,
                          {
                            staticClass: "auto-modeling-btn",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.stop()
                              },
                            },
                          },
                          [
                            _c(VIcon, [_vm._v("mdi-auto-fix")]),
                            _vm._v("Stop Generation"),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.value.description
                      ? _c(
                          VBtn,
                          {
                            staticClass: "auto-modeling-btn",
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.explain()
                              },
                            },
                          },
                          [
                            _c(VIcon, [_vm._v("mdi-auto-fix")]),
                            _vm._v("Generate description"),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _c("template", { slot: "element" }, [
        _c(
          "div",
          [
            _c(
              "span",
              {
                staticClass: "panel-title",
                staticStyle: { "margin-left": "15px" },
              },
              [_vm._v("Read/Write Authority")]
            ),
            _c(
              VCard,
              { attrs: { flat: "" } },
              [
                _vm.isReadOnly
                  ? _c(
                      VCardText,
                      [
                        _vm.selectedTemplateLists
                          ? _c(VAutocomplete, {
                              attrs: {
                                items: _vm.selectedTemplateLists,
                                "item-text": "display",
                                "item-value": "template",
                                disabled: _vm.isReadOnly,
                                label: "Preferred Platform",
                              },
                              model: {
                                value: _vm.value.preferredPlatform,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "preferredPlatform", $$v)
                                },
                                expression: "value.preferredPlatform",
                              },
                            })
                          : _vm._e(),
                        _c(VAutocomplete, {
                          attrs: {
                            items: _vm.userLists,
                            filled: "",
                            chips: "",
                            color: "blue-grey lighten-2",
                            label: "Select",
                            "item-text": "userName",
                            "return-object": "",
                            disabled: _vm.isReadOnly,
                            multiple: true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function (data) {
                                  return [
                                    _c(
                                      VChip,
                                      _vm._b(
                                        {
                                          attrs: {
                                            "input-value": data.selected,
                                            close: "",
                                          },
                                          on: {
                                            click: data.select,
                                            "click:close": function ($event) {
                                              return _vm.remove(data.item)
                                            },
                                          },
                                        },
                                        "v-chip",
                                        data.attrs,
                                        false
                                      ),
                                      [
                                        _c(
                                          VAvatar,
                                          { attrs: { left: "" } },
                                          [
                                            _c(VImg, {
                                              attrs: { src: data.item.userPic },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(data.item.userName) +
                                            "\n                            "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3069940191
                          ),
                          model: {
                            value: _vm.value.members,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "members", $$v)
                            },
                            expression: "value.members",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.isReadOnly && _vm.value.members
              ? _c(
                  VCard,
                  { attrs: { flat: "" } },
                  [
                    _c(VCardText, [
                      _c(
                        "div",
                        [
                          _c(VDataTable, {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.userTableHeaders,
                              items: _vm.value.members,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.readable",
                                  fn: function ({ item }) {
                                    return [
                                      _c(VSimpleCheckbox, {
                                        attrs: { ripple: false },
                                        model: {
                                          value: item.readable,
                                          callback: function ($$v) {
                                            _vm.$set(item, "readable", $$v)
                                          },
                                          expression: "item.readable",
                                        },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "item.writable",
                                  fn: function ({ item }) {
                                    return [
                                      _c(VSimpleCheckbox, {
                                        attrs: { ripple: false },
                                        model: {
                                          value: item.writable,
                                          callback: function ($$v) {
                                            _vm.$set(item, "writable", $$v)
                                          },
                                          expression: "item.writable",
                                        },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "item.admin",
                                  fn: function ({ item }) {
                                    return [
                                      _c(VSimpleCheckbox, {
                                        attrs: { ripple: false },
                                        model: {
                                          value: item.admin,
                                          callback: function ($$v) {
                                            _vm.$set(item, "admin", $$v)
                                          },
                                          expression: "item.admin",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2297616359
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }